@mixin menu-level-padding($direction){
    $elem: '';
    @for $i from 3 through 10 {
        $elem: if($i == 3, ".sub-menu", selector-nest($elem, ".sub-menu"));      
        #{$elem + ' .mat-mdc-button'} { 
            @if $direction == "rtl" {
                padding-right: 20px * $i; 
            } 
            @else{
                padding-left: 20px * $i; 
            }
        }
    }
}

@mixin flex($direction: row, $justify: flex-start, $align: stretch) {
    display: flex !important;
    flex-direction: $direction !important;
    justify-content: $justify !important;
    align-items: $align !important;
}

@mixin btn($background, $color, $fontSize: 14px, $borderColor: #CCE7FF, $borderRadius: 6px) {
    background: $background !important;
    color: $color !important;
    font-size: fontSize !important;
    border: 1px solid $borderColor !important;
    border-radius: $borderRadius !important;
}

