$main-font-family: "Lato", sans-serif;

$top-toolbar-height: 56px;
$horizontal-menu-height: 64px;
$footer-toolbar-height: 56px;

$sidenav-width: 220px;
$compact-sidenav-width: 170px;
$mini-sidenav-width: 66px;
$link-blue: #0881eb;
$primary: #00539b;
$secondary: #FFD600;
$light: #ffffff;
$primary-text-color:#1F2937;
$primary-800:#003666;
$bg-light-blue: #E5F3FF;;
$dark-grey:#1E1E1E;
$medium-gray: #555555;
$light-gray-blue:#EAECF0;
$blue-gray-20: #D4DBEA;
$lightest-gray-blue: #F9FBFE;
$vibrant-blue: #006DCC;
$light-blue: #E5F3FF;
$blue-gray-60: #667085;


$primary-700:#005299;
$primary-800:#003666;
$primary-text: #1f2937;
$success: #16a34a;
$secondary-blue: #006dcc;
$destructive: #dc2626;
$success-600: #079455;
$warning: #f59e0b;
$pastel-blue: #c9cfee;
$stroke-blue: #9dbad9;
$uss-primary-dark: #093066;
$uss-green: #00a94f;
$uss-yellow: #ffd500;
$white: #ffffff;
$secondary: #8392ab;

$gray-50: #fafafa;
$gray-200: #e9eaeb;
$gray-400: #a4a7ae;
$gray-600: #535862;
$gray-300:#D5D7DA;
$gray-800: #252b37;
$secondary-text: #717680;
$gray-900: #181d27;
$gray-700: #414651;
$light-steel-gray: #535862;
$slate-gray: #495057;
$soft-white: #fdfdfd;
$obsidian-gray: #0a0d120d;
$cloud-gray: #d5d7da;
$pearl-gray: #f0f0f1;
$blush-pink: #fee4e2;
$transparent-crimson: #b4231880;
$scarlet: #b42318;
$deep-charcoal: #181d27;
$misty-gray: #61656c80;
$storm-gray: #61656c;
$graphite-green: #414651;
$emerald-green: #06764780;
$forest-green: #067647;
$mint-cream: #dcfae6;
$steel-gray: #717680;
$gray-600: #535862;
$gray-800: #252b37;

$gray-50: #fafafa;
$gray-800: #252b37;
$gray-100: #d4dbea;
$Eerie-Black: #1e1e1e;
$Davys-Grey: #555555;
$danger: #fda29b;
$danger-100: #fee4e2;
$Neautral-700: #374151;
$Neautral-800: #1f2937;
$light: #ffffff;
$pastel-blue-100: #cce7ff;
$light-sea-green: #17b26a;
$form-field-radius: 6px;
$border-color: #d5d7da;
$table-header-color: #fafafa;
$gray-500:#717680;


