// @import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
// Oswald font
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

// indigo-pink, deeppurple-amber, purple-green and pink-bluegrey.
@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/dragula/dist/dragula.min.css";
// @import "../node_modules/leaflet/dist/leaflet.css";

@import 'app/theme/styles/custom_bootstrap';
@import "app/theme/styles/base";
@import "app/theme/styles/spaces";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import "app/theme/styles/rtl";

// Toastr
@import "ngx-toastr/toastr";