/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
    50 : #e0eaf3,
    100 : #b3cbe1,
    200 : #80a9cd,
    300 : #4d87b9,
    400 : #266daa,
    500 : #00539b,
    600 : #004c93,
    700 : #004289,
    800 : #00397f,
    900 : #00296d,
    A100 : #9cb9ff,
    A200 : #6994ff,
    A400 : #3670ff,
    A700 : #1d5eff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
  ),
);

$md-secondary: (
    50 : #fffae0,
    100 : #fff3b3,
    200 : #ffeb80,
    300 : #ffe24d,
    400 : #ffdc26,
    500 : #ffd600,
    600 : #ffd100,
    700 : #ffcc00,
    800 : #ffc600,
    900 : #ffbc00,
    A100 : #ffffff,
    A200 : #fffbf2,
    A400 : #ffecbf,
    A700 : #ffe4a6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
  ),
);

$uss-primary: mat.m2-define-palette($md-primary, 600, 700, 800);
$uss-accent: mat.m2-define-palette($md-secondary, 600, 700, 800);
$uss-warn: mat.m2-define-palette(mat.$m2-red-palette, 600);

$uss-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $uss-primary,
      accent: $uss-accent,
      warn: $uss-warn,
    ),
  )
);