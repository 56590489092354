@use "@angular/material" as mat;
@import "./variables";
$custom-typography: mat.m2-define-typography-config(
  $body-1: mat.m2-define-typography-level(14px, 20px, 400, $main-font-family),
  $button:
    mat.m2-define-typography-level(
      14px,
      22px,
      600,
      $main-font-family,
      $letter-spacing: 0.02em
    ),
    $font-family: $main-font-family,
);
@include mat.all-component-typographies($custom-typography);
@include mat.core();
@include mat.form-field-density(-4);
@include mat.radio-density(-4);


@import "theme-reset";
.app {
  &.uss-theme {
    @import "skins/uss-theme";
    @include mat.all-component-colors($uss-theme);
    @include theme-reset($uss-theme);
  }
}
