@use "@angular/material" as mat;
@import "variables";

html,
body {
  height: 100%;
}
body {
  font-family: $main-font-family !important;
  font-size: 14px !important;
  line-height: 1.5;
  overflow-x: hidden;
  //ios specific
  -webkit-overflow-scrolling: auto;
  height: 100vh;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
}

.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}
.vh-100 {
  height: 100vh !important;
}
.w-auto {
  width: auto !important;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-justify {
  text-align: justify;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.o-hidden {
  overflow: hidden !important;
}
.h-300p {
  height: 300px;
}
.h-400p {
  height: 400px;
}
.transition {
  transition: 0.2s;
}
.white {
  color: #fff;
}
.draggable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.toolbar-dropdown-menu.mat-mdc-menu-panel {
  margin-top: 9px;
  right: 53px !important;
  border-radius: 0 !important;
  overflow: hidden;
  .mat-mdc-menu-content {
    padding: 0;
  }
}

.logo {
  padding-left: 10px;
  font-weight: 700;
  text-decoration: none;
  min-width: calc(#{$sidenav-width} - 60px);
  color: #fff;
  img {
    width: 90px;
  }
  &.mobile {
    min-width: calc(#{$mini-sidenav-width} - 16px);
    span {
      display: none;
    }
  }
}

.sidenav .mat-drawer-inner-container {
  overflow: hidden;
}

.app {
  height: 100%;
  padding-top: env(safe-area-inset-top);
  .page-wrapper.mat-drawer-container {
    z-index: unset;
    .mat-drawer-content {
      height: auto;
      overflow: hidden;
    }
  }
  .main-content-inner {
    min-height: calc(
      100vh - #{$top-toolbar-height} - #{$footer-toolbar-height} -
        env(safe-area-inset-top) - 10px
    );
    background-color: #f5f7fa;
  }
  &.fixed-header {
    .page-wrapper.mat-drawer-container {
      .mat-drawer-content {
        padding-top: $top-toolbar-height;
      }
    }
    .header {
      position: fixed;
      z-index: 999;
      width: 100%;
    }
    .sidenav {
      padding-top: $top-toolbar-height;
      margin-top: env(safe-area-inset-top);
    }
    &.fixed-sidenav {
      overflow: auto;
      .sidenav {
        padding-top: 0;
      }
      .page-wrapper.mat-drawer-container {
        .mat-drawer-content {
          padding-top: $top-toolbar-height;
          height: calc(100vh - (#{$top-toolbar-height}));
          overflow: auto;
        }
      }
      &.fixed-footer {
        .page-wrapper.mat-drawer-container {
          .mat-drawer-content {
            padding-top: $top-toolbar-height;
            padding-bottom: $footer-toolbar-height;
            min-height: calc(100vh - (#{$top-toolbar-height} + #{$footer-toolbar-height}));
            height: calc(
              100svh - (#{$top-toolbar-height} + #{$footer-toolbar-height})
            );
          }
        }
      }
    }
  }
  &.fixed-sidenav {
    .sidenav {
      position: fixed;
      z-index: 3;
    }
    .footer {
      transition: 0.3s;
      width: 100%;
    }
    &.compact {
      .footer {
        width: calc(100% - #{$compact-sidenav-width});
        &.full-width {
          width: 100%;
        }
      }
    }
    &.mini {
      .footer {
        width: calc(100% - #{$mini-sidenav-width});
        &.full-width {
          width: 100%;
        }
      }
    }
  }
  &.fixed-footer {
    .page-wrapper.mat-drawer-container {
      .mat-drawer-content {
        padding-bottom: $footer-toolbar-height;
      }
    }
    .sidenav {
      padding-bottom: $footer-toolbar-height;
    }
    .footer {
      position: fixed;
      bottom: 0;
      right: 0;
      transition: 0.3s;
      width: calc(100% - #{$sidenav-width});
      float: right;
      &.full-width {
        width: 100%;
      }
    }
    &.fixed-sidenav {
      .sidenav {
        padding-bottom: 0;
        z-index: 4;
      }
    }
  }
  &.compact {
    .logo {
      min-width: calc(#{$compact-sidenav-width} - 16px);
    }
    .sidenav {
      width: $compact-sidenav-width;
    }
    .user-block {
      img {
        width: 90px;
        height: 90px;
      }
    }
    .menu-item {
      .mat-mdc-button {
        font-size: 14px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 0;
        flex-direction: column !important;
        align-items: center !important;
        height: auto;
        .mat-icon.menu-icon {
          margin: 0;
        }
        .mat-icon.menu-expand-icon {
          top: 28px;
        }
        .menu-title {
          line-height: 24px;
        }
      }
    }
    .sub-menu {
      .mat-mdc-button {
        padding-left: 0 !important;
      }
    }
  }
  &.mini {
    .logo {
      min-width: calc(#{$mini-sidenav-width} - 16px);
      span {
        display: none;
      }
    }
    .sidenav {
      width: $mini-sidenav-width;
    }
    .user-block {
      img {
        width: 45px;
        height: 45px;
      }
    }
    .menu-item {
      .mat-mdc-button {
        // min-width: $mini-sidenav-width;
        min-width: 45px;
        padding: 8px 0;
        flex-direction: column !important;
        align-items: center !important;
        height: auto;
        .mat-icon.menu-icon {
          margin: 0;
          font-size: 24px;
          height: 24px;
          width: 24px;
        }
        .mat-icon.menu-expand-icon {
          top: 4px;
          right: -4px;
        }
        .menu-title {
          display: none;
        }
      }
    }
    .sub-menu {
      .mat-mdc-button {
        padding-left: 0 !important;
      }
    }
  }
  &.horizontal-menu {
    .footer {
      width: 100% !important;
    }
    &.compact {
      .logo {
        min-width: calc(#{$sidenav-width} - 16px);
        span {
          display: block;
        }
      }
      .horizontal-menu-item {
        .mat-mdc-button {
          padding-top: 4px;
          padding-bottom: 4px;
          &.parent-item {
            padding: 0;
            flex-direction: column !important;
            align-items: center !important;
            place-content: center !important;
            height: $horizontal-menu-height;
          }
          .mdc-button__label {
            line-height: 18px;
          }
          .horizontal-menu-icon {
            margin: 0;
          }
        }
        .horizontal-sub-menu {
          .mat-mdc-button {
            flex-direction: row;
            justify-content: flex-start !important;
            align-items: center;
            .horizontal-menu-icon {
              margin-right: 10px;
            }
          }
        }
      }
    }
    &.mini {
      .logo {
        min-width: calc(#{$sidenav-width} - 16px);
        span {
          display: block;
        }
      }
      .horizontal-menu-item {
        .mat-mdc-button {
          min-width: 56px;
          place-content: center !important;
          .horizontal-menu-title {
            display: none;
          }
          .horizontal-menu-icon {
            margin: 0;
          }
        }
        .horizontal-sub-menu {
          width: 56px;
          .mat-mdc-button {
            justify-content: center !important;
          }
          .horizontal-menu-icon {
            margin: 0;
            height: 24px;
            width: 24px;
            line-height: 24px;
            font-size: 24px;
          }
        }
      }
    }
  }
}

/* Material Components */
.mat-mdc-card {
  overflow: hidden;
  &.custom-card {
    .mat-mdc-card-header {
      padding: 0 0 0 16px;
      .mat-mdc-card-title {
        font-size: 14px;
      }
    }
    .mat-mdc-card-actions {
      padding-left: 6px;
      padding-right: 6px;
    }
  }
}
.mat-mdc-list .mat-mdc-list-item,
.mat-nav-list .mat-list-item,
.mat-selection-list .mat-list-item,
.mat-menu-item {
  font-size: 14px !important;
}

.mat-mdc-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs {
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  width: 14px;
  vertical-align: middle;
}
.mat-mdc-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.icon-sm {
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  width: 18px;
  vertical-align: middle;
}
.mat-mdc-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg {
  font-size: 36px;
  line-height: 36px;
  height: 36px;
  width: 36px;
  vertical-align: middle;
}
.mat-mdc-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg {
  font-size: 48px;
  line-height: 48px;
  height: 48px;
  width: 48px;
  vertical-align: middle;
}

.vertical-tabs.mat-mdc-tab-group {
  flex-direction: row;
  .mat-mdc-tab-labels {
    flex-direction: column;
  }
  .mdc-tab-indicator__content--underline {
    display: none;
  }
  .mat-mdc-tab-body-wrapper {
    padding: 0 16px;
  }
}

.mat-mdc-form-field.hide-hint {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mdc-line-ripple::before {
    content: unset;
  }
}

//default scrollbar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 0px;
  &:hover {
    background: #cccccc;
  }
  &:active {
    background: #888888;
  }
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 0px;
  &:hover {
    background: #666666;
  }
  &:active {
    background: #333333;
  }
}
::-webkit-scrollbar-corner {
  background: transparent;
}

// logo header
.logo_header {
  img {
    width: 100px;
    // height: 50px;
  }
}



.sm_inputs {
  .mdc-text-field--outlined {
    height: 30px !important;
  }
  .mdc-text-field .mdc-floating-label {
    top: 48% !important;
  }
  .mdc-floating-label--float-above {
    top: 0 !important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    /* height: 16px; */
    top: 26%;
    position: absolute;
    transform: translateY(-50%);
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    --mat-mdc-form-field-label-transform: translateY(-20px)
      scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  }

  .mat-mdc-select-arrow svg {
    // top: 28% !important;
    transform: translate(-50%, -84%) !important;
  }

  .mat-form-field-outline-start {
    border-radius: 12px 0 0 12px !important;
    min-width: 12px !important;
  }

  .mat-form-field-outline-end {
    border-radius: 0 12px 12px 0 !important;
  }
  .mat-mdc-select-value-text {
    position: absolute !important;
    top: -28% !important;
  }

  .mat-mdc-form-field-icon-suffix {
    position: relative !important;
    top: -7px !important;
  }
}

.textarea {
  .mdc-text-field--outlined {
    height: 100px !important;
  }
  #mat-input-1 {
    height: 74px !important;
  }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    top: 90% !important;
  }
}

// error display none
.error-display-none .mat-mdc-form-field-bottom-align {
  display: none !important;
}

// all btns heights
.button {
  height: 40px !important;
}
/* round btn class*/
.round_btn {
  border-radius: 20px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  // -webkit-appearance: none;
  margin: 0;
}

.user-spinner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  right: 0;
  bottom: 0px;
  left: 0;
  z-index: 2000;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 3px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 3px;
}

.screen_bg {
  background-color: #f3f3f3;
}
.white_bg {
  background-color: #ffffff;
}
.filled {
  background-color: #57bc18 !important;
}
.next_step {
  background-color: #82baeb !important;
}

.phone-number {
  color: #74788d;
  text-decoration: none;
}

.error-display-none .mat-mdc-form-field-bottom-align {
  display: none !important;
}


.cursor-pointer {
  cursor: pointer !important;
}

td {
  color: #2c2c2c;
  font-size: 13px;
  font-weight: 400;
}


.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}

// dot loader css
.loading {
  align-items: center;
  display: flex;
  justify-content: center;
}

.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: gray !important;
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 10px;
  margin: 3px;
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.3s;
}

// dot loader animation
@keyframes dot {
  0% {
    background-color: #00539b;
    transform: scale(1);
  }
  50% {
    background-color: #00539b;
    transform: scale(1.3);
  }
  100% {
    background-color: #00539b;
    transform: scale(1);
  }
}

.border-radius-8 {
  border-radius: 8px !important;
}

.border-radius-6 {
  border-radius: 6px !important;
}

.border-radius-16 {
  border-radius: 16px !important;
}
.fs-24{
  font-size: 24px !important;
}

.fs-20{
  font-size: 20px !important;
}

.fs-18{
  font-size: 18px !important;
}

.fs-16{
  font-size: 16px !important;
}
.fs-14{
  font-size: 14px !important;
}

.fs-12{
  font-size: 12px !important;
}



.font-weight-500{
  font-weight: 500 !important;
}

.font-weight-600{
  font-weight: 600 !important;
}

.features-ul-alignment{
  box-sizing: border-box !important;
  padding-left: 14px !important;
}

.btn-disable{
  filter: grayscale(1);
}

.mat-mdc-text-field-wrapper {
  border-radius: 9px !important;
}

 .mdc-notched-outline__leading {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

 .mdc-notched-outline__trailing {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}


.mat-mdc-radio-button .mdc-radio {
  transform: scale(0.9);
}

.mat-datepicker-toggle{
  @include mat.icon-button-density(-4);
  padding-right: 5px !important;
}


