//----------Google Maps--------------- 
.map-container {
    width: 100% !important;  
    height: calc(100vh - 144px) !important;
}
.contact-map .map-container {
    height: 310px !important;
}

//----------Leaflet Maps---------------
#leaflet-map {
  width: 100%;
  height: 490px;
  height: calc(100vh - 112px);
}

//----------ng-scrollbar---------------
ng-scrollbar {
    --scrollbar-size: 6px !important;
    --scrollbar-thumb-color: rgba(204,204,204,0.8) !important;
    --scrollbar-thumb-hover-color: #ccc !important;
    --scrollbar-border-radius: 4px !important;
    --scrollbar-hover-size: 8px !important;
    --scrollbar-padding: 2px !important;
}

//---------- NGU carousel ---------------
.tile{
    box-shadow: none !important;
}
.nguCarouselPoints {
    list-style-type: none;
    text-align: center;
    padding: 12px;
    margin: 0;
    white-space: nowrap;
    overflow: auto;
    box-sizing: border-box;
}
.nguCarouselPoints  li {
    display: inline-block;
    border-radius: 50%;
    background: #6b6b6b;
    padding: 4px;
    margin: 0 3px;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    transition: 0.4s;
}
.nguCarouselPoints  li.active {
    background: #6b6b6b;
    transform: scale(1.5);
}
.nguCarouselPoints li:hover {
    cursor: pointer;
}